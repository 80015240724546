let cartItems = [];
let subscribers = [];

const cartService = {
    // Get all items in the cart
    getCartItems: () => cartItems,

    // Add or update an item in the cart
    addToCart: (item, qty) => {
        const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.id === item.id);
    
        if (existingItemIndex !== -1) {
            // Update the existing item's quantity and total price
            cartItems[existingItemIndex] = {
                ...cartItems[existingItemIndex],
                qty: cartItems[existingItemIndex].qty + qty,
                totalPrice: cartItems[existingItemIndex].totalPrice + item.sales_price * qty,
            };
        } else {
            // Add new item to the cart
            cartItems.push({
                ...item,
                qty,
                totalPrice: item.sales_price * qty,
            });
        }
    
        // Notify subscribers of the updated cart
        cartService.notifySubscribers();
    },
    

    // Clear all cart items
    clearCart: () => {
        cartItems = [];
        cartService.notifySubscribers();
    },

    // Remove a single item by id
    removeFromCart: (id) => {
        cartItems = cartItems.filter((item) => item.id !== id);
        cartService.notifySubscribers();
    },
    subscribe: (callback) => {
        subscribers.push(callback);
    },

    unsubscribe: (callback) => {
        subscribers = subscribers.filter((subscriber) => subscriber !== callback);
    },

    notifySubscribers: () => {
        subscribers.forEach((callback) => callback(cartItems));
    },
};

export default cartService;
