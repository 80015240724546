import React, { useState, useEffect } from "react";
import Search from "../search/index.js";
import { Link } from "react-router-dom";
import * as Constants from "../../api/constants.js";
import Axios from "axios";
import { Token } from '../../App.js'
import cartService from "../../services/cartService.js";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { RiInstallLine } from 'react-icons/ri';
import { MdCloudUpload } from "react-icons/md";

const Header = ({ toggleSidebar, tokenOne }) => {
  const navigate = useNavigate();

  const [clogo, setClogo] = useState(false);
  const [nlogo, setNlogo] = useState(true);
  const [data, setData] = useState();

  const showToast = (icon, message) => {
    Swal.fire({
      toast: true,
      position: "top",
      icon: icon,
      title: message,
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {

    const updateCartCount = (cartItems) => {
      setCartCount(cartItems.length);
    };

    // Subscribe to cart updates
    cartService.subscribe(updateCartCount);

    // Initial load
    updateCartCount(cartService.getCartItems());

    // Cleanup subscription on unmount
    return () => cartService.unsubscribe(updateCartCount);

  }, [cartService.getCartItems()]);


  useEffect(() => {
    const config = {
      headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
    };
    Axios.get(Constants.COMPANYDATA + Constants.C_ID, config)
      .then((response) => {
        const result = response.data.result;
        result.map(items => {
          setData(items);
          setClogo(true)
          setNlogo(false)
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    let collection = localStorage.getItem('NewToken');
    if (collection === null) {

    } else {

    }
  }, []);


  const handleCartClick = () => {
    if (cartCount === 0) {
      showToast("error", "Please add some product to the cart.");

    } else {
      navigate('/cart');
    }

  };

  const uploadUiOpen = () => {
    navigate('/upload');

  }

  return (
    <header
      style={{ backgroundColor: "#EA1920" }}
      className="text-gray-600 body-font shadow-lg lg:sticky lg:top-0 z-[99999]"
    >
      {/* Top Row: Menu, Logo, Cart */}
      <div
        className="container mx-auto flex flex-wrap items-center justify-between px-6 py-3 "
      >
        {/* Menu Button */}
        <button
          className="rounded bg-primary p-3 flex justify-center items-center text-xs font-medium uppercase text-white transition duration-150 ease-in-out lg:hidden"
          data-te-sidenav-toggle-ref
          data-te-target="#sidenav-3"
          aria-controls="#sidenav-3"
          aria-haspopup="true"
          onClick={toggleSidebar}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {/* Logo */}
        <Link
          to="/"
          className="title-font font-medium items-center text-gray-900"
        >
          {clogo && (
            <img
              src={Constants.AdminUrl + data.company_logo}
              alt="logo"
              className="w-48 sm:w-72 mb-2"
            />
          )}
          {nlogo && (
            <div className="text-white underline decoration-black hover:decoration-white decoration-double font-extralight text-4xl hover:text-black cursor-pointer text-center transition">
              Logo
            </div>
          )}
        </Link>

        <div style={{ width: "50%", }} className="hidden lg:block px-[10px] pr-[10px] align-middle justify-center mb-4 mt-4 ">
          <Search />
        </div>

        {/* <div className="order-3 lg:ml-4 flex items-center hidden lg:block">
          <a
            href='https://ecommerce.netbees.com.sg/apk/catalogue_app.apk'
            className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none"
            data-te-sidenav-link-ref>
            <span
              className="mr-4 [&>svg]:h-4 [&>svg]:w-4">
              <RiInstallLine />
            </span>
            <span>Download App</span>
          </a>

          <a onClick={uploadUiOpen}
            className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none "
            data-te-sidenav-link-ref
            target='_blank'>
            <span
              className="mr-4 [&>svg]:h-4 [&>svg]:w-4">
              <MdCloudUpload />
            </span>
            <span>Upload Item List</span>
          </a>

        </div> */}

        <div className="order-3 lg:ml-4 flex items-center">
          <a
            href='https://ecommerce.netbees.com.sg/apk/catalogue_app.apk'
            className=" hidden mr-2 cursor-pointer lg:block items-center truncate rounded-[5px] p-2 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none"
            data-te-sidenav-link-ref>
            Download App
          </a>
          <a onClick={uploadUiOpen}
            className=" hidden mr-2 cursor-pointer lg:block items-center truncate rounded-[5px] p-2 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none"
            data-te-sidenav-link-ref
            target='_blank'>
            Upload Item List
          </a>
        </div>

        {/* Cart Button */}
        <div className="relative">
          <button

            className="rounded-full bg-black p-2 flex justify-center items-center"
            onClick={handleCartClick}
          >
            <span
              className="absolute bg-white text-black text-xs rounded-full px-1.5 py-0.5"
              style={{ top: -5, right: -8 }}
            >
              {cartCount}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              className="h-5 w-5"
            >
              <path d="M7 18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-2 .9-2 2s.9 .9 2 2 2-.9 2-2-.9-2-2-2zm-9.83-2h11.66c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.3-.55.28-1.21-.06-1.73s-.88-.77-1.49-.77H5.21L4.27 2H1v2h2.28l3.6 9.19L5.25 15c-.41.73-.39 1.59.04 2.3s1.15 1.18 1.88 1.18H19v-2H7.17l.9-2z" />
            </svg>
          </button>
        </div>
      </div>


      {/* Bottom Row: Search Box */}
      <div style={{ width: "100%", }} className=" px-[30px] pr-[21px] align-middle justify-center mb-4 lg:hidden">
        <Search />
      </div>
      <nav
        style={{ width: "100%", zIndex: 10 }}
        className="flex  flex-wrap items-center text-base md:ml-auto lg:hidden"
      ></nav>

    </header>

    // <header
    //   style={{ backgroundColor: "#EA1920" }}
    //   className="text-gray-600 body-font shadow-lg lg:sticky lg:top-0 z-[99999]"
    // >
    //   {/* Top Row: Menu, Logo, Search Box, Cart */}
    //   <div
    //     className="container mx-auto flex flex-wrap items-center justify-between px-6 py-3 lg:py-5"
    //   >
    //     {/* Menu Button */}
    //     <button
    //       className="rounded bg-primary p-3 flex justify-center items-center text-xs font-medium uppercase text-white transition duration-150 ease-in-out lg:hidden"
    //       data-te-sidenav-toggle-ref
    //       data-te-target="#sidenav-3"
    //       aria-controls="#sidenav-3"
    //       aria-haspopup="true"
    //       onClick={toggleSidebar}
    //     >
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         viewBox="0 0 24 24"
    //         fill="currentColor"
    //         className="h-5 w-5"
    //       >
    //         <path
    //           fillRule="evenodd"
    //           d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
    //           clipRule="evenodd"
    //         />
    //       </svg>
    //     </button>

    //     {/* Logo */}
    //     <Link
    //       to="/"
    //       className="title-font font-medium items-center text-gray-900"
    //     >
    //       {clogo && (
    //         <img
    //           src={Constants.AdminUrl + data.company_logo}
    //           alt="logo"
    //           className="w-48 sm:w-72 mb-2"
    //         />
    //       )}
    //       {nlogo && (
    //         <div className="text-white underline decoration-black hover:decoration-white decoration-double font-extralight text-4xl hover:text-black cursor-pointer text-center transition">
    //           Logo
    //         </div>
    //       )}
    //     </Link>

    //     {/* Search Box (Desktop) */}
    //     <div
    //       style={{ width: "100%" }}
    //       className="hidden lg:flex lg:justify-center lg:flex-1 lg:mx-6"
    //     >
    //       <Search />
    //     </div>

    //     {/* Cart Button */}
    //     <div className="relative">
    //       <button
    //         className="rounded-full bg-black p-2 flex justify-center items-center"
    //         onClick={handleCartClick}
    //       >
    //         <span
    //           className="absolute bg-white text-black text-xs rounded-full px-1.5 py-0.5"
    //           style={{ top: -5, right: -8 }}
    //         >
    //           {cartCount}
    //         </span>
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           viewBox="0 0 24 24"
    //           fill="white"
    //           className="h-5 w-5"
    //         >
    //           <path d="M7 18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-2 .9-2 2s.9 .9 2 2 2-.9 2-2-.9-2-2-2zm-9.83-2h11.66c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.3-.55.28-1.21-.06-1.73s-.88-.77-1.49-.77H5.21L4.27 2H1v2h2.28l3.6 9.19L5.25 15c-.41.73-.39 1.59.04 2.3s1.15 1.18 1.88 1.18H19v-2H7.17l.9-2z" />
    //         </svg>
    //       </button>
    //     </div>
    //   </div>

    //   {/* Search Box (Mobile/Tablet) */}
    //   <div
    //     style={{ width: "100%" }}
    //     className="px-[30px] pr-[21px] align-middle justify-center mb-4 lg:hidden"
    //   >
    //     <Search />
    //   </div>
    //   <nav
    //     style={{ width: "100%", zIndex: 10 }}
    //     className="flex flex-wrap items-center text-base md:ml-auto lg:hidden"
    //   ></nav>
    // </header>


  );
};

export default Header;
