import { CiSearch } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import spinningLoaders from "../../assets/loaderBlack.svg";
import Axios from "axios";
import SearchItems from "../searchitems";
import * as Constans from "../../api/constants";


const Search = () => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showList, setShowList] = useState(true);
  const [searching, setSearching] = useState(false);
  const [alertIds, setAlertIds] = useState([]);

  const config = {
    headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
  };

  useEffect(() => {
    if (search !== "") {
      Axios.get(Constans.ALL_SEARCSH_PRODUCTS + Constans.C_ID + '/' + search, config)
        .then((response) => {
          const result = response.data.result;
          setFilteredData(result);
          setSearching(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [search]);

  const changeHandler = (e) => {
    setSearch(e.target.value);
    // if (!showList) setShowList(true);
  };
  const submitHandler = (e) => {
    // e.preventDefault();
    // applyFilters("searchText", search);
    // setShowList(false);
    // navigate("/products");
  };

  return (
    <>
      <form
        onSubmit={submitHandler}
        className={`flex justify-center  bg-gray-100 rounded focus:ring-2 focus:ring-purple-200 focus:bg-transparent border border-gray-300 focus:border-purple-500 text-base outline-none text-gray-700 py-1 px-3 leading-4 transition-colors duration-200 ease-in-out ${search && showList ? "rounded-t-md" : "rounded-t-md"
          } text-sm transition`}
      >
        <input
          className="w-full py-2 px-3 bg-transparent focus:outline-none"
          type="search"
          value={search}
          placeholder="Search Products..."
          onChange={changeHandler}
        />
        <CiSearch style={{ fontSize: 25, marginTop: 4 }} />
      </form>
      {search && showList && (
        <div className=" relative">
          <ul className="absolute bg-amber-50 w-full max-h-72 overflow-hidden rounded-b-md z-[9000]">
            {searching ? (
              <li className="h-10 flex items-center justify-center">
                <img src={spinningLoaders} alt="Searching..." />
              </li>
            ) : filteredData.length > 0 ? (
              filteredData.map((product) => {
                let newImage = '';

                if (product.item_image == null) {
                  newImage = '';
                } else {
                  const Images = product.item_image;
                  const urlsArray = Images.split(',');
                  const newArr = urlsArray.filter(item => item !== '');
                  const ImgNew = newArr.slice(0, 1);
                  ImgNew.map(item => {
                    let match = /\.(\w+)$/.exec(item);
                    let type = match ? `.${match[1]}` : ``;
                    let name = item.substring(item.lastIndexOf('.') + 0, item) + '_thumb';
                    newImage = name + type;
                  })

                }

                const stockLabel = product.stock === 0 ? 'Out of Stock' : 'In Stock';

                if (product && product.item_image) {
                  const Images = product.item_image;
                  if (typeof Images === 'string') {
                    const urlsArray = Images.split(',');
                    const newArr = urlsArray.filter(item => item !== '');
                    const ImgNew = newArr.slice(0, 3);

                  } else {
                    console.error("product.item_image is not a valid string");
                  }
                } else {
                  console.error("product or product.item_image is undefined");
                }
                return (
                  <li key={product.id} className=" z-[9998]">
                    <SearchItems
                      product={product}
                      isSearch={true}
                      setSearch={setSearch}
                      newImage={newImage}
                    />
                  </li>
                )

              })
            ) : (
              <li className=" h-24 text-lg flex items-center justify-center">
                No Item to show
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Search;
