import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as Constans from "../../api/constants";
import cartService from "../../services/cartService";

const SearchItems = ({ product, isSearch, setSearch, newImage }) => {

    const navigate = useNavigate();
    const [selectProduct, setSelectProduct] = useState([]);
    const [cart, setCart] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const confirmAddToCart = () => {
        cartService.addToCart(selectProduct, quantity);

        setShowPopup(false);
        setQuantity(1);
        setSearch("");
    };

    const handleCancel = () => {
        setShowPopup(false);
        setQuantity(""); // Clear the input field
    };

    return (
        <>
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9998]">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                        <h3 className="text-lg font-semibold mb-4 text-center">Enter Quantity</h3>
                        <input
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Quantity"
                        />
                        <div className="flex justify-between">
                            <button
                                className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-600 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded"
                                onClick={confirmAddToCart}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Add to Cart"
                            >
                                Add to Cart
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`m-auto flex flex-col gap-2 h-4/5 pt-[4px] pb-[4px] rounded-sm shadow-sm bg-white/[0.6] mb-2 mx-6 ${isSearch ? "cursor-pointer hover:bg-black/5" : ""
                    }`}

                style={{ zIndex: 7500 }}
            >
                <div className="flex  flex-wrap w-full">
                    <div className="flex flex-wrap xs:flex-nowrap  flex-1  gap-5" onClick={() => {
                        if (isSearch) {
                            setSearch("");
                            navigate(`products/${product.item_name}/${product.id}`);
                        }
                    }}>
                        <div
                            className={` bg-black/[0.075] ${isSearch ? "h-10 w-10 " : "h-12 w-12"
                                } rounded-full flex `}
                        >
                            <img src={Constans.AdminUrl + newImage} alt="" className="object-fit w-full " style={{ borderRadius: 500 }} />
                        </div>
                        <div className="flex justify-center flex-col " >
                            <h2 className=" text-base font-semibold">{product.item_name.slice(0, 35)}</h2>
                        </div>

                    </div>

                </div>
                <button
                    className="absolute right-8 shadow-sm bg-blue-600 hover:bg-blue-800 text-white rounded-full p-2 z-[999]"
                    onClick={(e) => {
                        e.preventDefault();
                        setSelectProduct(product)
                        setShowPopup(true); // Show the popup
                    }}
                    style={{ marginTop: 6, zIndex: 8888 }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 3h2l.4 2M7 13h10l4-8H5.4m-2.1 6L7 21m4 0a1 1 0 102 0m-2 0H9m10-10h-4"
                        />
                    </svg>
                </button>
            </div>
        </>

    )
}

export default SearchItems;
