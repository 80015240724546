import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import './pstyles.css';
import Axios from 'axios';
import * as Constants from '../../api/constants';
import { Img } from 'react-image';
import LoadAni from '../loadani';
import cartService from "../../services/cartService";
import { Tooltip } from 'react-tooltip'
import he from 'he';

export let NewProduct = '';

const Products = ({ products, tokenOne }) => {

  const [allProducts, setAllProducts] = useState([products]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [cart, setCart] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [quantity, setQuantity] = useState(1);


  const handleAddToCart = () => {
    alert(`Product added to cart: ${console.log(selectProduct)}\nQuantity: ${quantity}`);
    setShowPopup(false); // Close the popup after adding
  };

  const confirmAddToCart = () => {
    cartService.addToCart(selectProduct, quantity);

    setShowPopup(false);
    setQuantity(1);
  };

  const handleCancel = () => {
    setShowPopup(false);
    setQuantity(""); // Clear the input field
  };

  const config = {
    headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
  };

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(allProducts));
  }, [allProducts]);

  useEffect(() => {
    setAllProducts(products);
  }, [])

  const backButton = () => {
    var first = products[0];
    Axios.get(Constants.ALL_PRODUCTS + Constants.C_ID + "/0/0/" + first.id, config)
      .then((response) => {
        const result = response.data.result;
        setAllProducts(result);
        // const Images = item.item_image;
        //     const urlsArray = Images.split(',');
        //     const newArr = urlsArray.filter(item => item !== '')
        //     setImages(newArr.slice(0, 3));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const forwardButton = () => {
    let lastElement = products[products.length - 1];
    Axios.get(Constants.ALL_PRODUCTS + Constants.C_ID + "/0/0/" + lastElement.id, config)
      .then((response) => {
        const result = response.data.result;
        setAllProducts(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const SendProduct = (product) => {
    localStorage.setItem('ProductDetails', JSON.stringify(product));
  }

  return (
    <>
      <section className="text-gray-600 lg:hidden body-font mb-5 items-center justify-center" style={{ marginTop: 1, alignItems: 'center', marginLeft: 8 }}>
        <style>
          {`
      @media (max-width: 350px) {
        .custom-sm\\:w-1\\/2 {
          width: 48%;
          margin: 1%; /* Add margin for spacing */
        }
      }

      @media (min-width: 351px) and (max-width: 665px) {
        .custom-md\\:w-1\\/2 {
          width: 48%;
          margin: 1%; /* Add margin for spacing */
        }
      }

      @media (min-width: 670px) {
        .custom-lg\\:w-1\\/2 {
          width: 32%;
          margin-left: 1.3333333% /* Add margin for spacing */
        }
        .main-one{
          padding-right: 15px
        }
      }

      @media (min-width: 670px) and (max-width: 917px) {
        .custom-lg\\:w-1\\/2 {
          width: 32%;
          margin-left: 1.3333333% /* Add margin for spacing */
        }
        .main-one{
          padding-right: 15px
        }
      }
    `}
        </style>
        <div className="container main-one items-center">
          {showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                <h3 className="text-lg font-semibold mb-4 text-center">Enter Quantity</h3>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Quantity"
                />
                <div className="flex justify-between">
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>

                  <button
                    className="bg-blue-600 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded"
                    onClick={confirmAddToCart}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-wrap">
            {allProducts.map((product) => {
              let newImage = '';

              if (product.item_image == null) {
                newImage = '';
              } else {
                const Images = product.item_image;
                const urlsArray = Images.split(',');
                const newArr = urlsArray.filter(item => item !== '');
                const ImgNew = newArr.slice(0, 1);
                ImgNew.map(item => {
                  let match = /\.(\w+)$/.exec(item);
                  let type = match ? `.${match[1]}` : ``;
                  let name = item.substring(item.lastIndexOf('.') + 0, item) + '_thumb';
                  newImage = name + type;
                })

              }

              const stockLabel = product.stock === 0 ? 'Out of Stock' : 'In Stock';

              if (product && product.item_image) {
                const Images = product.item_image;
                if (typeof Images === 'string') {
                  const urlsArray = Images.split(',');
                  const newArr = urlsArray.filter(item => item !== '');
                  const ImgNew = newArr.slice(0, 3);

                } else {
                  console.error("product.item_image is not a valid string");
                }
              } else {
                console.error("product or product.item_image is undefined");
              }
              return (

                <Link
                  to={`/products/${product.item_slug}/${product.id}`}
                  className="w-full md:w-1/2 lg:w-1/3 sm:w-1/2 mt-2 custom-sm:w-1/2 custom-md:w-1/2 custom-lg:w-1/2 border-2 rounded-md hover:border-slate-500 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ..."
                  style={{ marginBottom: 1 }}
                  onClick={() => SendProduct(product)}
                >

                  <div>
                    <p
                      style={{ position: "relative", marginTop: 10, zIndex: 20, fontSize: 14 }}
                      className={`text-center w-[50%] ${product.stock === 0 ? "bg-red-600" : " bg-green-600"
                        } font-semibold text-white`}
                    >
                      {stockLabel}
                    </p>
                  </div>

                  <a className="block text-sm relative -mt-[31px] z-10 h-52 rounded overflow-hidden">
                    <Img
                      alt={product.item_name}
                      className="object-fill object-center w-full h-full block"
                      src={Constants.AdminUrl + newImage}
                      loader={
                        <div>
                          <LoadAni />
                        </div>
                      }
                    />
                    {/* Add to Cart Button */}

                    <button
                      className="absolute bottom-2 right-2 bg-blue-600 hover:bg-blue-800 text-white rounded-full p-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectProduct(product)
                        setShowPopup(true); // Show the popup
                      }}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Add to Cart"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4m-2.1 6L7 21m4 0a1 1 0 102 0m-2 0H9m10-10h-4"
                        />
                      </svg>
                    </button>
                  </a>
                  <div className="mt-2 p-2">
                    <h4 className="text-gray-500 uppercase text-center text-[10px] font-bold tracking-widest title-font mb-1">
                      SKU: {product.sku}
                    </h4>
                    <h2 className="text-gray-900 title-font text-center text-sm font-medium">
                      {product.item_name}
                    </h2>
                  </div>
                </Link>

              );
            })}
          </div>
        </div>
      </section>

      <section className="text-gray-600 hidden lg:block body-font mb-5 items-center justify-center" style={{ marginTop: 1, alignItems: 'center', marginLeft: 8 }}>
        <style>
          {`
            @media (max-width: 350px) {
              .custom-sm\\:w-1\\/2 {
                width: 48%;
                margin: 1%; /* Add margin for spacing */
              }
            }

            @media (min-width: 351px) and (max-width: 665px) {
              .custom-md\\:w-1\\/2 {
                width: 48%;
                margin: 1%; /* Add margin for spacing */
              }
            }

            @media (min-width: 670px) {
              .custom-lg\\:w-1\\/2 {
                width: 32%;
                margin-left: 1.3333333% /* Add margin for spacing */
              }
              .main-one{
                padding-right: 15px
              }
            }

            @media (min-width: 670px) and (max-width: 917px) {
              .custom-lg\\:w-1\\/2 {
                width: 32%;
                margin-left: 1.3333333% /* Add margin for spacing */
              }
              .main-one{
                padding-right: 15px
              }
            }

            @media (min-width: 1024px) {
              .custom-lg\\:w-1\\/2 {
                width: 15.6%; /* Width for 3 items per row */
                margin-left: 1%; /* Adjusted margin for spacing */
              }
              .main-one {
                 /* Adjusted padding */
                padding-left: 60px; 
                margin-top: -115px
              }
            }

          `}
        </style>
        <div className="container main-one items-center">
          {showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                <h3 className="text-lg font-semibold mb-4 text-center">Enter Quantity</h3>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Quantity"
                />
                <div className="flex justify-between">
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>

                  <button
                    className="bg-blue-600 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded"
                    onClick={confirmAddToCart}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-wrap">
            {allProducts.map((product) => {
              let newImage = '';

              if (product.item_image == null) {
                newImage = '';
              } else {
                const Images = product.item_image;
                const urlsArray = Images.split(',');
                const newArr = urlsArray.filter(item => item !== '');
                const ImgNew = newArr.slice(0, 1);
                ImgNew.map(item => {
                  let match = /\.(\w+)$/.exec(item);
                  let type = match ? `.${match[1]}` : ``;
                  let name = item.substring(item.lastIndexOf('.') + 0, item) + '_thumb';
                  newImage = name + type;
                })

              }

              const stockLabel = product.stock === 0 ? 'Out of Stock' : 'In Stock';

              if (product && product.item_image) {
                const Images = product.item_image;
                if (typeof Images === 'string') {
                  const urlsArray = Images.split(',');
                  const newArr = urlsArray.filter(item => item !== '');
                  const ImgNew = newArr.slice(0, 3);

                } else {
                  console.error("product.item_image is not a valid string");
                }
              } else {
                console.error("product or product.item_image is undefined");
              }
              return (

                <Link
                  to={`/products/${product.item_slug}/${product.id}`}
                  className="w-full md:w-1/2 lg:w-1/3 sm:w-1/2 mt-2 custom-sm:w-1/2 custom-md:w-1/2 custom-lg:w-1/2 border-2 rounded-md hover:border-slate-500 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ..."
                  style={{ marginBottom: 1 }}
                  onClick={() => SendProduct(product)}
                >

                  <div>
                    <p
                      style={{ position: "relative", marginTop: 10, zIndex: 20, fontSize: 14 }}
                      className={`text-center w-[50%] ${product.stock === 0 ? "bg-red-600" : " bg-green-600"
                        } font-semibold text-white`}
                    >
                      {stockLabel}
                    </p>
                  </div>

                  <a className="block text-sm relative -mt-[31px] z-10 h-52 rounded overflow-hidden">
                    <Img
                      alt={product.item_name}
                      className="object-fill object-center w-full h-full block"
                      src={Constants.AdminUrl + newImage}
                      loader={
                        <div>
                          <LoadAni />
                        </div>
                      }
                    />
                    {/* Add to Cart Button */}

                    <button
                      className="absolute bottom-2 right-2 bg-blue-600 hover:bg-blue-800 text-white rounded-full p-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectProduct(product)
                        setShowPopup(true); // Show the popup
                      }}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Add to Cart"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4m-2.1 6L7 21m4 0a1 1 0 102 0m-2 0H9m10-10h-4"
                        />
                      </svg>
                    </button>
                  </a>
                  <div className="mt-2 p-2">
                    <h4 className="text-gray-500 uppercase text-center text-[10px] font-bold tracking-widest title-font mb-1">
                      SKU: {product.sku}
                    </h4>
                    <h2 className="text-gray-900 title-font text-center text-sm font-medium">
                      {product.item_name}
                    </h2>
                  </div>
                </Link>

              );
            })}
          </div>
        </div>
      </section>

      <div class=" max-w-lg p-5 mb-16 container flex justify-center mx-auto">
        <div class="flex flex-row mx-auto">
          <button onClick={backButton} type="button" class="bg-gray-800 text-white rounded-l-md border-r border-gray-100 py-2 hover:bg-red-700 hover:text-white px-3">
            <div class="flex flex-row align-middle">
              <svg class="w-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
              </svg>
              <p class="ml-2">Prev</p>
            </div>
          </button>
          <button onClick={forwardButton} type="button" class="bg-gray-800 text-white rounded-r-md py-2 border-l border-gray-200 hover:bg-red-700 hover:text-white px-3">
            <div class="flex flex-row align-middle">
              <span class="mr-2">Next</span>
              <svg class="w-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </div>
          </button>
        </div>
      </div></>

  );
};

export default Products;
