import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import * as Constants from "../../api/constants";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const MobileNav = () => {

    const [mobile, setItems] = useState(false);
    const [latitude, setLatited] = useState(false);
    const [longitude, setLongited] = useState(false);
    const [details, setDetails] = useState([]);

    useEffect(() => {

        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
        };

        Axios.get(Constants.COMPANYDATA + Constants.C_ID, config)
            .then((response) => {
                const result = response.data.result;
                result.map(items => {
                    setLatited(items.latitude)
                    setLongited(items.longitude)
                    setItems(items.mobile)
                    setDetails(items)
                });
                console.log(result)
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [])


    const companyName = "Apexsofy";
    const lat = 6.9271; // Replace with your latitude
    const lon = 79.8612;



    return (
        <>


            <div className="container mx-auto px-4 lg:px-20 pt-6 pb-1">
                <div className="flex flex-wrap justify-between">
                    {/* Company Info */}
                    <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                        <h2 className="text-2xl font-bold text-yellow-400 mb-2">{details?.company_name}</h2>
                        <Link
                            to={'/category'}
                            data-tooltip-target="tooltip-home"
                            type="button"
                            className="inline-flex flex-col items-center justify-center px-5  hover:bg-gray-50 group"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                                />

                            </svg>
                            <p className="text-gray-500 text-[10px] dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">Categories</p>

                        </Link>
                    </div>

                    {/* Location Map */}
                    <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                        <h3 className="text-xl font-semibold text-yellow-400 mb-2">Location</h3>
                        <iframe
                            src={`https://www.google.com/maps?q=${details?.latitude},${details?.longitude}&hl=es&z=14&output=embed`}
                            className="w-full h-40 border-2 border-gray-700 rounded-lg"
                            allowFullScreen
                            loading="lazy"
                            title="Company Location"
                        ></iframe>
                        <p className="mt-2 text-gray-400">
                            {details?.address}
                        </p>
                    </div>

                    {/* Contact Details */}
                    <div className="w-full lg:w-1/3 px-10">
                        <h3 className="text-xl font-semibold text-yellow-400 mb-2">Contact Us</h3>
                        <ul className="space-y-2">
                            <li className="flex">
                                <IoMdCall style={{ marginTop: 3, marginRight: 4, color: 'yellow' }} />
                                <a
                                    href={`tel:+${details?.phone}`}
                                    className="text-gray-400 hover:text-white transition"
                                >
                                    +94 771 234 567
                                </a>
                            </li>
                            <li className="flex">
                                <FaWhatsapp style={{ marginTop: 3, marginRight: 4, color: '#3cda3a' }} />
                                <a
                                    href={`https://wa.me/${details?.mobile}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-green-400 transition"
                                >
                                    WhatsApp: +94 771 234 567
                                </a>
                            </li>
                            <li className="flex">
                                <FaYoutube style={{ marginTop: 3, marginRight: 4, color: '#f91818' }} />
                                <a
                                    href={details?.youtube}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-red-500 transition"
                                >
                                    YouTube
                                </a>
                            </li>
                            <li className="flex">
                                <FaFacebook style={{ marginTop: 3, marginRight: 4, color: '#1896f9' }} />
                                <a
                                    href={details?.facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-blue-500 transition"
                                >
                                    Facebook
                                </a>
                            </li>
                            <li className="flex">
                                <FaInstagram style={{ marginTop: 3, marginRight: 4, color: '#f91893' }} />
                                <a
                                    href={details?.insta}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-pink-500 transition"
                                >
                                    Instagram
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="mt-10 mb-6 border-t border-gray-700 pt-4 text-center">
                    <p className="text-white text-sm">
                        &copy; {new Date().getFullYear()} {details.company_name} All Rights Reserved | Developed by <a href="https://netbeesconsulting.com/" target='_blank' className=" cursor-pointer hover:text-orange-600">NetBees</a>
                    </p>
                </div>
            </div>

        </>

    );
};

export default MobileNav;
