import React, { useState, useEffect } from "react";
import * as Constants from '../../api/constants';
import Axios from 'axios';
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import cartService from "../../services/cartService.js";
import moment from 'moment';
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router";

const CartUi = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        phone: "",
        email: "",
    });

    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const updateCartCount = (cartItems) => {
            setCartItems(cartItems);
        };

        // Subscribe to cart updates
        cartService.subscribe(updateCartCount);

        // Initial load
        updateCartCount(cartService.getCartItems());

        // Cleanup subscription on unmount
        return () => cartService.unsubscribe(updateCartCount);

    }, [cartService.getCartItems()]);

    const showToast = (icon, message) => {
        Swal.fire({
            toast: true,
            position: "top",
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        });
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle send button
    const handleSend = () => {
        const { name, email, mobile } = formData;

        // Check for required fields
        if (!name || !email || !mobile) {
            showToast("error", "Please fill out the Required fields.");
            return;
        } else if (cartItems.length < 1) {
            showToast("error", "Please add some product to the cart.");
            return;
        }

        AddCustomer();

    };

    // Handle delete cart item
    const handleDeleteCartItem = (id) => {
        cartService.removeFromCart(id);
    };
    const config = {
        headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
    };

    const AddCustomer = () => {
        setIsLoading(true)
        const { name, mobile, phone, email } = formData;
        console.log(formData);
        Axios.post(Constants.AddCustomer, {
            customer_name: name,
            mobile: mobile,
            phone: phone,
            email: email,
            company_id: Constants.C_ID
        }, config)
            .then(({ data }) => {
                const result = data.result;
                const id = result.insertId || result.id
                console.log(id);
                setIsLoading(false)
                AddPo(id);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    var DateMoment = moment().utcOffset('+05:30')
        .format('YYYY-MM-DD');

    var TimeMoment = moment().utcOffset('+05:30')
        .format('HH:mm:ss');

    const AddPo = (id) => {
        Axios.post(Constants.POST_PO, {
            id: 0,
            customer_id: id,
            grand_total: '0.00',
            discount: '0.00',
            company_id: Constants.C_ID,
            user_id: 51,
            status: 1,
            order_date: DateMoment + " " + TimeMoment,
        }, config)
            .then(({ data }) => {
                const result = data.result;
                console.log(result);
                PoItems(data.result.insertId);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const PoItems = (id) => {
        console.log('PO ID' + id)
        const promises = cartItems.map((items) => {
            return Axios.post(Constants.POST_PO_ITEMS, {
                po_id: id,
                item_id: items.id,
                qty: items.qty,
                price_per_unit: '0.00',
                discount_type: 'Fixed',
                discount_input: '0.00',
                discount_amt: '0.00',
                unit_total_cost: '0.00',
                total_cost: '0.00',
                po_item_id: 0
            }, config);
        });
        Promise.all(promises).then(({ data }) => {
            showToast("success", "Products submitted successfully!");
            setFormData({
                name: "",
                mobile: "",
                phone: "",
                email: ""
            });
            cartService.clearCart();
            navigate('/');
            setIsLoading(false)
        }).catch((error) => {
            showToast("error", "Have some Error, Please try again");
            setIsLoading(false)
        });
    }



    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-6">{isLoading && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-[9999]">
                <div className="text-center">
                    <svg
                        className="w-16 h-16 text-white animate-spin mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                        ></path>
                    </svg>
                </div>
            </div>
        )}

            {/* Cart List Section */}
            <div className="w-[90%] bg-white rounded-lg shadow-lg p-6 mb-6">
                <h2 className="text-xl font-bold text-center text-green-700 mb-4">
                    Your Cart Items
                </h2>

                {/* Cart Items */}
                <ul className="divide-y divide-gray-200 mb-4">
                    {cartItems.length > 0 ? (
                        cartItems.map((item) => (
                            <li key={item.id} className="flex items-center justify-between py-3">
                                <div>
                                    <p className="text-gray-800 font-medium">{item.item_name}</p>
                                    <p className="text-gray-500 text-sm">Qty: {item.qty}</p>
                                </div>
                                <button
                                    onClick={() => handleDeleteCartItem(item.id)}
                                    className="text-red-600 hover:text-red-800 font-medium text-xl"
                                >
                                    <MdDeleteForever />
                                </button>
                            </li>
                        ))


                    ) : (
                        <li className="py-6 text-center text-gray-500 font-medium flex flex-col items-center">
                            {/* Unhappy Face Icon */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-12 h-12 text-gray-400 mb-2"
                            >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-3.5-3.5h7c.28 0 .5.22.5.5s-.22.5-.5.5h-7a.5.5 0 010-1zm2.25-6c-.69 0-1.25-.56-1.25-1.25S10.06 8 10.75 8s1.25.56 1.25 1.25S11.44 11 10.75 11zm4.5 0c-.69 0-1.25-.56-1.25-1.25S14.56 8 15.25 8s1.25.56 1.25 1.25S15.94 11 15.25 11z" />
                            </svg>

                            {/* Message */}
                            Cart is empty - please add some products
                        </li>
                    )}
                </ul>


                {/* Send Button */}

            </div>
            {/* Form Section */}
            <div className="w-[90%] bg-white rounded-lg shadow-lg p-6 mb-20">
                <h2 className="text-xl font-bold text-center text-blue-700 mb-4">
                    Contact Details
                </h2>

                {/* Name Input */}
                <div className="mb-4">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Name <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter your name"
                        className="w-full rounded-lg border border-gray-300 p-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                    />
                </div>

                {/* Mobile and Phone Inputs */}
                <div className="mb-4 flex gap-4">
                    <div className="w-1/2">
                        <label
                            htmlFor="mobile"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Mobile <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="tel"
                            id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleInputChange}
                            placeholder="Mobile number"
                            className="w-full rounded-lg border border-gray-300 p-2 focus:ring-green-500 focus:border-green-500"
                        />
                    </div>
                    <div className="w-1/2">
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Phone
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="Phone number"
                            className="w-full rounded-lg border border-gray-300 p-2 focus:ring-purple-500 focus:border-purple-500"
                        />
                    </div>
                </div>

                {/* Email Input */}
                <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter your email"
                        className="w-full rounded-lg border border-gray-300 p-2 focus:ring-pink-500 focus:border-pink-500"
                        required
                    />
                </div>
                <label
                        htmlFor="name"
                        className="block text-sm font-medium text-green-700 mb-1"
                    >
                        Cash on delivery is available
                    </label>
                <button
                    onClick={handleSend}
                    className="w-full bg-blue-600 text-white font-medium text-lg py-2 rounded-lg hover:bg-blue-700"
                >
                    Send
                </button>
            </div>


        </div>
    );



};

export default CartUi;
