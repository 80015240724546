import React, { useEffect, useState } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import MobileNav from "./components/mobilenav";
import RightSide from "./modules/rightside";
import { HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from "react-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-tooltip/dist/react-tooltip.css';

if (typeof window !== "undefined") {
  const resizeObserverErrorHandler = (e) => {
    if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
      e.stopImmediatePropagation();
    }
  };

  window.addEventListener("error", resizeObserverErrorHandler);
}

const APP = (<BrowserRouter>
  <React.StrictMode>
    <HelmetProvider>
      <nav className="sidebar">
        <div className="scrollbox">
          <div className="scrollbox-inner">
            <App />
          </div>
        </div>
        
      </nav>
      {/* <div className="Main_div">
        

        <div className="content">
          <div className="content-wrapper">
            <RightSide />

          </div>
        </div>
      </div> */}
    </HelmetProvider>


  </React.StrictMode>
</BrowserRouter>);



const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
serviceWorkerRegistration.unregister();
// src/index.js


reportWebVitals();
