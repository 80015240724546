import React, { useState, useRef } from "react";
import * as Constants from '../../api/constants';
import Axios from 'axios';
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import cartService from "../../services/cartService.js";
import moment from 'moment';
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router";

const UploadCustomerForm = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        phone: "",
        email: "",
    });

    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);

    const fileInputRef = useRef(null);

    const showToast = (icon, message) => {
        Swal.fire({
            toast: true,
            position: "top",
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        });
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Validate file type
            const validTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
            if (!validTypes.includes(file.type)) {
                showToast("error", "Invalid file type. Please select an image (jpg, jpeg, png, gif).");
                setImage(null);
                setPreview(null);
                fileInputRef.current.value = ""; // Reset the file input
                return;
            }
            console.log(file)
            console.log(URL.createObjectURL(file))
            setImage(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleDeleteImage = () => {
        setImage(null);
        setPreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the file input
        }
    };

    // Handle send button
    const handleSend = () => {
        const { name, email, mobile } = formData;

        // Check for required fields
        if (!name || !email || !mobile) {
            showToast("error", "Please fill out the Required fields.");
            return;
        } else if (!image) {
            showToast("error", "Please select an image to upload.");
            return;
        }

        AddCustomer();

    };


    const config = {
        headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
    };

    const AddCustomer = () => {
        setIsLoading(true)
        const { name, mobile, phone, email } = formData;
        console.log(formData);
        Axios.post(Constants.AddCustomer, {
            customer_name: name,
            mobile: mobile,
            phone: phone,
            email: email,
            company_id: Constants.C_ID
        }, config)
            .then(({ data }) => {
                const result = data.result;
                const id = result.insertId || result.id
                console.log(id);
                setIsLoading(false)
                AddPo(id);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    var DateMoment = moment().utcOffset('+05:30')
        .format('YYYY-MM-DD');

    var TimeMoment = moment().utcOffset('+05:30')
        .format('HH:mm:ss');

    const AddPo = (id) => {
        Axios.post(Constants.POST_PO, {
            id: 0,
            customer_id: id,
            grand_total: '0.00',
            discount: '0.00',
            company_id: Constants.C_ID,
            user_id: 51,
            status: 1,
            order_date: DateMoment + " " + TimeMoment,
        }, config)
            .then(({ data }) => {
                const result = data.result;
                console.log(result);
                itemImageUpload(data.result.insertId);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const PoItems = (id) => {
        console.log('PO ID' + id)
        const promises = cartItems.map((items) => {
            return Axios.post(Constants.POST_PO_ITEMS, {
                po_id: id,
                item_id: items.id,
                qty: items.qty,
                price_per_unit: '0.00',
                discount_type: 'Fixed',
                discount_input: '0.00',
                discount_amt: '0.00',
                unit_total_cost: '0.00',
                total_cost: '0.00',
                po_item_id: 0
            }, config);
        });
        Promise.all(promises).then(({ data }) => {
            showToast("success", "Products submitted successfully!");
            setFormData({
                name: "",
                mobile: "",
                phone: "",
                email: ""
            });
            cartService.clearCart();
            setIsLoading(false)
        }).catch((error) => {
            showToast("error", "Have some Error, Please try again");
            setIsLoading(false)
        });
    }

    const itemImageUpload = async (id) => {
        try {
            if (image) {
                // Create a file path (this may vary based on your backend's requirements)
                const filePath = `uploads/${Constants.C_ID}/po/${id}`;
                const fileName = image.name; // Get the file name from the `File` object
                const fileType = image.type; // Get the MIME type (e.g., image/png)

                // Create a FormData object for the file upload
                const formData = new FormData();
                formData.append('images', image); // Append the selected image
                formData.append('filePath', filePath);
                formData.append('table', 'po');
                formData.append('object_id', id);
                formData.append('company_id', Constants.C_ID);
                formData.append('create_thumb_only', 0);

                // Send the POST request to the server
                const response = await axios.post(Constants.upoladImages, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    console.log('Item image added successfully');
                    showToast("success", "Itme list uploaded successfully!");
                    handleDeleteImage(); // Clear the image after successful upload
                    setIsLoading(false);
                    navigate('/');
                } else {
                    console.error('Image upload failed:', response.statusText);
                    showToast("error", "Image upload failed. Please try again.");
                    setIsLoading(false);
                }
            } else {
                console.log('Image not selected');
                showToast("error", "No image selected for upload.");
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Upload error:', error);
            showToast("error", "An error occurred during the upload.");
            setIsLoading(false);
        }
    };


    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-6">{isLoading && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-[9999]">
                <div className="text-center">
                    <svg
                        className="w-16 h-16 text-white animate-spin mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                        ></path>
                    </svg>
                </div>
            </div>
        )}


            {/* Form Section */}
            <div className="w-[90%] bg-white rounded-lg shadow-lg p-6 mb-20">
                <h2 className="text-xl font-bold text-center text-blue-700 mb-4">
                    Contact Details
                </h2>

                {/* Name Input */}
                <div className="mb-4">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Name <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter your name"
                        className="w-full rounded-lg border border-gray-300 p-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                    />
                </div>

                {/* Mobile and Phone Inputs */}
                <div className="mb-4 flex gap-4">
                    <div className="w-1/2">
                        <label
                            htmlFor="mobile"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Mobile <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="tel"
                            id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleInputChange}
                            placeholder="Mobile number"
                            className="w-full rounded-lg border border-gray-300 p-2 focus:ring-green-500 focus:border-green-500"
                        />
                    </div>
                    <div className="w-1/2">
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Phone
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="Phone number"
                            className="w-full rounded-lg border border-gray-300 p-2 focus:ring-purple-500 focus:border-purple-500"
                        />
                    </div>
                </div>

                {/* Email Input */}
                <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter your email"
                        className="w-full rounded-lg border border-gray-300 p-2 focus:ring-pink-500 focus:border-pink-500"
                        required
                    />
                </div>
                {/* Image Upload Section */}
                <div className="mb-4">
                    <label htmlFor="image" className="block text-sm font-medium text-gray-700 mb-1">
                        Upload Image <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef} // Ref for resetting the input
                        className="w-full"
                    />
                    {preview && (
                        <div className="mt-4 relative">
                            <img src={preview} alt="Preview" className="w-32 h-32 object-cover rounded" />
                            <button
                                onClick={handleDeleteImage}
                                className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full p-1 hover:bg-red-700"
                            >
                                <MdDeleteForever size={24} />
                            </button>
                        </div>
                    )}
                </div>
                <label
                    htmlFor="name"
                    className="block text-sm font-medium text-green-700 mb-1"
                >
                    Cash on delivery is available
                </label>
                <button
                    onClick={handleSend}
                    className="w-full bg-blue-600 text-white font-medium text-lg py-2 rounded-lg hover:bg-blue-700"
                >
                    Send
                </button>
            </div>


        </div>
    );



};

export default UploadCustomerForm;
