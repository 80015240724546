// src/components/InstallPrompt.js

import React, { useState, useEffect } from 'react';
import { RiInstallLine } from 'react-icons/ri';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { useNavigate } from "react-router";
import { MdCloudUpload } from "react-icons/md";

const InstallPrompt = ({toggleSidebar}) => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstall = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
        }
    };

    const uploadUiOpen=()=>{
        navigate('/upload');
        toggleSidebar();
    }

    useEffect(() => {
        setTimeout(() => {
            if (deferredPrompt) {
                deferredPrompt.prompt();
            } //
        }, 4000)
    }, [])

    return (
        <>
            <li className="relative">
                <a onClick={handleInstall}
                    href='https://ecommerce.netbees.com.sg/apk/catalogue_app.apk'
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none"
                    data-te-sidenav-link-ref>
                    <span
                        className="mr-4 [&>svg]:h-4 [&>svg]:w-4">
                        <RiInstallLine />
                    </span>
                    <span>Download App</span>
                </a>
            </li>
            <li className="relative">
                <a onClick={uploadUiOpen} 
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none "
                    data-te-sidenav-link-ref
                    target='_blank'>
                    <span
                        className="mr-4 [&>svg]:h-4 [&>svg]:w-4">
                        <MdCloudUpload />
                    </span>
                    <span>Upload Item List</span>
                </a>
            </li>
        </>

    );
};

export default InstallPrompt;
